<template>
    <v-container fluid class="pa-0"  >
      <v-sheet class="border-b">      
        <div class="text-center main-breaking">

          <!-- <router-link :to="'/stream?lang='+$i18n.locale" class="title" style="    text-decoration: none;">
                  <span style="padding:0 10px; content: url('/assets/img/moharam/z-mohammed.svg')"></span>
                  <span class="ab_title tl"
                    style="cursor: pointer;font-size: 24px;font-weight: 700; color:#fff">  أحاديث عاشوراء</span>
                </router-link> -->

          <!-- <h1 class="gradient-text my-4">أحاديث عاشوراء</h1> -->
          <div class="news-text" ref="newsText" :data-text="dataText"></div>
        </div>
      </v-sheet>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'BreakingNews',
    data() {
      return {
        dataText : [],
        rnd: 0
      };
    },
    computed: {
        backgroundStyle() {
        return {
          // backgroundImage: "url('https://pic.imamhussain.org/photos/hd/1178-1851720038981.jpg')",
          backgroundColor: 'rgba(0, 0, 0, 0)', 
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        };
      }

    },
    mounted() {
      this.get()
    },
    methods: {
      async get () {
        await this.$axios.get("hadith")
        .then(response => {
          this.dataText = response.data
          this.rnd = this.dataText.length - 1;
          this.typeWriter(this.dataText[this.dataText.length - 1], 0);
        })
      },
      typeWriter(text, i) {
        const newsText = this.$refs.newsText;
        if (i < text.length) {
          newsText.textContent += text[i];
          i++;
          setTimeout(() => {
            this.typeWriter(text, i);
          }, 30);
        } else {
          setTimeout(() => {
            newsText.textContent = '';
            i = 0;
            setTimeout(() => {
              if (this.dataText.length > 1) {
                if (this.dataText.length - 2 != this.rnd) {
                  this.rnd = this.dataText.length - 2;
                } else {
                  this.rnd = this.dataText.length - 1;
                }
              } else {
                this.rnd = this.dataText.length - 1;
              }
              this.typeWriter(this.dataText[this.rnd], 0);
            }, 100);
          }, 5000);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .breaking-news {
    /* height: 300px; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
  }

  .main-breaking {
    width: 100vw;
    background-image: url(/assets/img/header-bg.png);
    background-repeat: repeat-x;
    /* background-size: cover; */
    height: 86px;
    border-bottom: 1px solid #cccccc5f !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news-text {
    color: white;
    border-bottom: 1px solid #cccccc5f;
    font-size: 16px;
    font-weight: bold;
    /* background:#00000098; */
    /* background-image: url('/assets/img/header-bg.png'); */
    background-repeat: repeat-x;
    /* background-size: cover; */
    height: 90px;
    padding: 20px;
    border-radius: 5px;
    width: 70vw;
    overflow: hidden;
    position: relative;
    direction: rtl;
    text-align: right;
    /* font-family: auto !important; */
    font-family: 'cairo_reg', sans-serif;
  }
  .gradient-text {
    font-size: 32px;
    font-weight: bold;
    background: linear-gradient(to right, red, #671e1e);
    -webkit-background-clip: text;
    color: transparent;
  }
  .caret {
    border-right: 2px solid red;
    display: inline-block;
    animation: blink-caret 1s infinite;
  }
  @keyframes blink-caret {
    0%, 100% { border-color: transparent; }
    50% { border-color: red; }
  }

  @media (min-width: 1264px) and (max-width: 1830px) {
  
    .main-breaking {
      height: 100px;
    }

    .news-text {
      height: 110px;
    }

    .news-text {
      width: 86vw;
    }
  
}

@media (min-width: 1570px) and (max-width: 1670px) {
  
  .news-text {
    width: 80vw;
  }

}

@media (min-width: 1671px) and (max-width: 1770px) {
  
  .news-text {
    width: 76vw;
  }

}

@media (min-width: 1771px) and (max-width: 1829px) {
  
  .news-text {
    width: 72vw;
  }

}

.border-b {
  background-color: #ffffff00 !important; 
}
  </style>
  